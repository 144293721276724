<template>
    <div>
      <Footer></Footer>
        <div class="content" v-if="ViewType==='seven'">
          
          <span class="title">
            第十届“大唐杯”闭幕式暨颁奖典礼精彩回顾
            <!--<p class="titext">
              <span>“大唐杯”组委会</span>
              &emsp;&emsp;&emsp;&emsp;&emsp;
              <span>发布于2022-07-19</span>
            </p>-->
          </span>
          <div class="contbox">
            <p class="text_t"> 7月30日，第十届“大唐杯”全国大学生新一代信息通信技术大赛暨全国双师型教师新一代信息通信技术大赛（以下简称：大赛）全国总决赛北京站、武汉站分别在北方工业大学、武汉职业技术学院落下帷幕。与会嘉宾就本届大赛在新产教联合培养、创新育人模式、创新工程实践等方面的促进作用和社会贡献给予了高度评价。</p>
            <div class="item_img">
              <img src="@/assets/images/s1.jpg" />
            </div>
            <p class="text_t"> 工业和信息化部人才交流中心战略发展委员会副主任陈新、教育培训处处长曲来军，中国通信协会副秘书长王涛，中信科移动通信技术股份有限公司副总经理李凯钢、副总经理唐家武，全国工业和信息化职业教育教学指导委员会副主任、教育部高等学校创新创业教育指导委员会委员马晓明，武汉职业技术学院党委书记李洪渠，北方工业大学副校长王建稳，北京邮电大学教务处处长卞佳丽、北京航空航天大学杭州创新研究院院长王俊，以及来自全国参与工程实践赛院校、企业相关领导出席全国总决赛闭幕式暨颁奖典礼。</p>
              <div class="item_img">
              <img src="@/assets/images/s2.jpg" />
            </div>
            <p class="text_t">此次大赛覆盖全国30个省市自治区，500多所院校3.5万余名学生、400余名教师报名参赛，参赛人数创历届新高。大赛设置信息通信工程实践赛和5G+创新应用设计赛两个赛道。其中，信息通信工程实践赛分为本科A组、本科B组、中/高职组、研究生组、一带一路国际留学生五个组别。在历届信息通信工程实践赛基础上新增5G+创新应用设计赛，以真设备、真场景、真问题、真应用作为竞赛核心，选手在5G技术原理、5G工程实践及5G+创新应用三方面进行真设备实践、真场景应用、真问题解决、真行业赋能等方面的比拼。</p>
            <div class="item_img">
              <img src="@/assets/images/s3.jpg" />
            </div>
            <p class="text_t">中信科移动作为本次大赛的主要承办单位，积极顺应数字化转型的时代浪潮，以“创新6G标准，加速5G建设，服务5G新场景，赋能5G新应用”为己任，不断深化同全国高校在实训平台、双师培养、通信产品设计开发、工程项目管理、5G+垂直产业创新应用、新工科数字化人才培养等方面深度融合，助力高校实现卓越人才培养和专业建设双提升，实现“企业用人”与“高校育人”双循环。大赛充分依托中信科移动在6G标准创新、5G技术商用、产品研发、网络建设及5G+垂直产业创新应用等方面的产业成果，不断提升参赛选手技术理论创新和工程实践创新的能力，助力高校电子信息类专业高质量发展，为数字化人才培养添砖加瓦。</p>
            <div class="item_img">
              <img src="@/assets/images/s4.jpg" />
            </div>
            <p class="text_t">在全国总决赛闭幕式暨颁奖典礼上，与会嘉宾一同回顾了大赛十年历程。中信科移动向全国信息通信教育领域做出辛勤奉献的教师代表颁发了10周年特别贡献奖纪念奖章。

大赛期间，来自北京航空航天大学、华北电力大学、武汉理工大学等多所院校的教学专家围绕“数智新时代产教融合模式及应用型人才培养创新实践”、“大唐杯赋能数字化人才发展”两个主题在高峰论坛上做了精彩分享。此外，中信科移动组织与会嘉宾和教师们前往中国信科集团展厅及校企共建的5G+产教融合基地进行了丰富多彩的参观交流活动。

本次大赛由工业和信息化部人才交流中心、中国通信企业协会主办，中信科移动通信技术股份有限公司、北京市教委北京高校电子信息类专业群承办，武汉职业技术学院、北方工业大学、北京邮电大学、北京航空航天大学协办。大赛已成功举办十年，并连续三年入选全国普通高校大学生竞赛排行榜，践行“以赛促学、以赛促教”理念，深入贯彻落实国家信息通信特别是5G技术发展战略部署，服务信息通信产业发展大局，深化产教融合、校企合作，为技术技能创新人才培养搭建育人平台。
</p>
            <div class="img_box">
            </div>
          </div>
        </div>
        <div class="content" v-if="ViewType==='eight'">
          
          <span class="title">
            第八届“大唐杯”比赛在京圆满谢幕
            <!--<p class="titext">
              <span>“大唐杯”组委会</span>
              &emsp;&emsp;&emsp;&emsp;&emsp;
              <span>发布于2022-07-19</span>
            </p>-->
          </span>
          <div class="contbox">
            <!-- <p class="text"> 2021年6月28日，第八届“大唐杯”全国大学生移动通信5G技术大赛全国总决赛在北方工业大学体育馆落下帷幕。中信科移动通信技术股份有限公司副总经理马军、工信部人才交流中心副主任李宁、中国通信协会副秘书长李北林、北方工业大学副校长栗苹、工业和信息化职业教育教学指导委员会委员孙卫平、北京邮电大学教务处长王卫东、中信科移动营销中心总经理赵力、中信科移动客服中心总经理蒋珀及北京市高校电子信息类专业群相关院校领导出席颁奖典礼。</p> -->
            <p class="text_t">2021年6月28日，第八届“大唐杯”全国大学生移动通信5G技术大赛全国总决赛在北方工业大学体育馆落下帷幕。中信科移动通信技术股份有限公司副总经理马军、工信部人才交流中心副主任李宁、中国通信协会副秘书长李北林、北方工业大学副校长栗苹、工业和信息化职业教育教学指导委员会委员孙卫平、北京邮电大学教务处长王卫东、中信科移动营销中心总经理赵力、中信科移动客服中心总经理蒋珀及北京市高校电子信息类专业群相关院校领导出席颁奖典礼。</p>
              <div class="item_img">
              <img src="@/assets/images/e1.jpg" />
            </div>
            <p class="text_t">与会嘉宾对大赛内容设置、组织策划、产教融合导向及所产生的社会贡献给予了充分肯定。尤其在庆祝建党百年的伟大历史时刻，对作为移动通信行业中最具影响力的大学生竞赛“大唐杯”全国大学生移动通信5G技术大赛成功进入2020全国普通高校大学生竞赛排行榜表达了祝贺和高度认可。 </p>
            <p class="text_t">不忘初心、砥砺前行，本届大赛在践行教育部、工信部倡导的“政、产、学、研、用”思路，推动专业教学与产业工程深度融合，落实落地新工科新举措，提升教学水平和人才培养质量等方面的影响力持续提升。此次大赛覆盖全国30个省市自治区,参与大赛的院校数量达到300余，其中不乏哈尔滨工业大学、武汉大学、电子科技大学、兰州大学、国防科技大学等多所985和211建设院校。多所教育部获批的双高建设职业院校均踊跃参赛。参赛师生共计1.5万余名，创下历届大赛参赛人数新高。1700余名选手经过省赛激烈的选拔后脱颖而出，以线上或线下的方式参加全国总决赛的桂冠争夺。本届大赛分为本科组、高职组、研究生邀请赛、教师邀请赛、一带一路留学生邀请赛五个类别，以真设备、真场景、真问题作为竞赛核心，选手们分别在5G技术原理、5G工程实践及5G+创新应用三方面进行了真实设备实践、真实场景应用、真实问题解决等工程能力的比拼。 </p>
            <div class="item_img">
              <img src="@/assets/images/e2.jpg" />
            </div>
            <p class="text_t">中信科移动副总经理马军在颁奖典礼致词时强调：大唐杯坚持立德树人的出发点和落脚点，充分发挥竞赛育人功能，深化竞赛与教学、竞赛与育人的内在联系，真正实现以赛促学、以赛促教。大赛以推进新工科建设，推动专业教学与工程实践教学深度融合，促进高校教学改革，提升教学水平和人才培养质量为根本遵循。充分吸收了中信科移动在5G标准创新、设备研发、网络部署及5G+垂直产业创新应用的产业成果。新一轮科技革命和产业变革正加速演进，并深刻改变着世界格局。能否掌握核心技术，不仅影响着全球产业布局，也决定着国家民族的前途命运。中信科移动主动顺应数字时代浪潮，以加速5G建设，服务5G新场景，赋能5G新应用为契机，不断深化同全国高校在5G通信技术实践实训平台、双师培养、高质量课程体系建设、5G+垂直产业创新应用等方面深度融合，助力高校实现人才培养和教学质量双提升，实现企业用人与高校育人平台双循环。      

信息时代人才战略是5G应用推进的重要支撑，更是在充满挑战的时代加速数字化转型的重要引擎，人才发展是通信行业发展的重要因素。作为信息通信领域中央企业的领军代表之一，中信科移动将继续参与大赛的所有高校和兄弟单位共同携手，以大赛为纽带，构建移动通信产业与人才培养服务生态圈，做新时代数字化产业人才培养的技术支撑，实现与高校培养体系的融合，培养符合产业标准的通信人才，助力国家信息产业的发展。</p>
            <div class="item_img">
              <img src="@/assets/images/e3.jpg" />
            </div>
            <p class="text_t">北方工业大学副校长栗苹、工业和信息化部人才交流中心副主任李宁、中国通信协会副秘书长李北林、工信部行指委委员孙卫平、北京邮电大学教务处处长王卫东先后致词。大家高度认可大唐杯在推动通信人才培养、促进专业建设高质量发展的突出作用，并表示今后将继续通力合作，从政策、行业和育人等方面进一步创新移动通信人才培养的理念和模式，打造行业竞赛的金字招牌。

大赛期间，参赛院校的教学专家们齐聚一堂，围绕“以赛促学、以赛促教、共迎5G数字化转型时代新挑战”，从5G工程实践教学、课程体系改革、校企合作服务产学研课题、应用型人才培养等方面开展了电子信息类专业建设高峰论坛，形成了多项交流合作意向和共识，对办赛质量提升和教学业务拓展提供了重要参考。大赛期间各高校专家与专业教师们受邀参观了中信科移动国家重点实验室，并对大赛的未来发展方向与竞赛模式提供了新的思路与建议。
</p>
            <div class="img_box">
            </div>
          </div>
        </div>
        <NinthNPage :ViewType="ViewType" />
        <NinthBPage :ViewType="ViewType" />
        <NinthWPage :ViewType="ViewType" />
        <playVPage :ViewType="ViewType" />
        <elevenPage :ViewType="ViewType" />
        <elevenPageB :ViewType="ViewType" />
        <newGeneration :ViewType="ViewType" />
    <Bottom></Bottom>
    </div>
</template>
  
  <script>
  import Footer from '@/components/Footer/Footer.vue'
  import Bottom from '@/components/Bottom/Bottom.vue'
  import NinthNPage from './NinthNPage.vue'
  import NinthBPage from './NinthBPage.vue'
  import NinthWPage from './NinthWPage.vue'
  import playVPage from './playVPage.vue'
  import elevenPage from './elevenPage.vue'
  import elevenPageB from './elevenPageB.vue'
  import newGeneration from './newGeneration.vue'
  import $ from "jquery";
  import { useRoute, useRouter } from 'vue-router'
  export default {
    name: 'ViewDetail',
    components: {
      Footer,
      Bottom,
      NinthNPage,
      NinthBPage,
      NinthWPage,
      playVPage,
      elevenPage,
      elevenPageB,
      newGeneration
    },
    data(){
      return {
        ViewType:''
      }
    },
    methods:{
    },
    mounted(){
    },
    created(){
      this.ViewType=this.$route.query.vType

      
    },
  }
  </script>
  
  <style scoped lang="scss">

  @import './Brief.scss';
  </style>
  