<template>
    <div class="content" v-if="this.ViewType==='eleven'">
          
          <span class="title">
            第十一届“大唐杯”全国大学生新一代信息通信技术大赛组委会全体会议在武汉召开
          </span>
          <div class="contbox">
              <div class="item_img">
              <img src="@/assets/images/elPic.jpg" />
            </div>
            <p class="text_t">3月30日上午，第十一届“大唐杯”全国大学生新一代信息通信技术大赛组委会全体会议在武汉举行。竞赛主办方工业和信息化部人才交流中心、中国通信企业协会领导、中信科移动通信技术股份有限公司教育业务总监孙中亮、重庆工程学院电子信息学院院长何世彪、武汉职业技术学院电子信息工程学院副院长李雪等60余位组委会成员出席。</p>
            <p class="text_t">会上，组委会听取了《“大唐杯”全国大学生新一代信息通信技术大赛分析报告解读》并审议了《“大唐杯”全国大学生新一代信息通信技术大赛章程<2024版>》，本次会议同时邀请了竞赛专家委员与竞赛评委，共同探讨大赛的未来发展方向，分享了以赛促学教学成果案例。并围绕大赛的赛制设计、评审标准、制度规范等展开了深入的讨论和交流。</p>
            <div class="item_img">
              <img src="@/assets/images/eleven01.jpg" />
            </div>
            <p class="text_t">孙中亮对“大唐杯”分析报告解读予以深度解读。他指出，要提高赛事站位，围绕新质生产力产出导向，加速培养电子信息类急需人才、全方位战略人才；要打通专业融合，进一步扩大参赛规模与专业覆盖，加速教学成果孵化；聚焦新一代信息技术，拓展新场景、新应用，对接产业发展，围绕“卡脖子”问题和典型案例进行赛题转化；以产学研用一体化为目标，提升竞赛含金量，培育具有核心竞争力的竞赛生态圈。</p>
            <div class="item_img">
              <img src="@/assets/images/eleven02.png" />
            </div>
            <p class="text_t">何世彪教授对重庆工程学院组织学科专业竞赛进行了“以赛促学、以赛促教、以赛促创”的主题分享。他指出，学科专业以就业为导向，通过赛事覆盖专业、赛事贯通学科、以赛代训等多种方式，提高了学生的创新能力与就业竞争力，结合“通信仿真”实验室与“车联网技术实验室”推进创新人才培养，近五年来在“大唐杯”等赛事中取得了喜人的成绩。</p>
            <div class="item_img">
              <img src="@/assets/images/eleven03.png" />
            </div>
            <p class="text_t">李雪教授表示，武汉职业技术学院通过深耕“大唐杯”竞赛，依托校内实践教学平台与校外产业集群实践基地，实现了虚实结合、工学结合、赛训结合。学院将竞赛深度融入学科，打造“六个课堂”全方位育人，已经形成了实践化、数字化、国际化的“岗课训赛证”职业教育以赛促学体系。</p>
            <div class="item_img">
              <img src="@/assets/images/eleven04.png" />
            </div>
            <p class="text_t"> 会议指出，竞赛是“以赛促教、以赛促学”的实际落脚点，未来要在拔尖人才培养、新质人才培养上继续发力，要对标国家与产业需求，把握时代特征深化产业、学科、竞赛融通，围绕产业链与教育链深耕，为新一代信息通信人才培养构建坚实的基础。</p>
            <div class="img_box">
            </div>
          </div>
        </div>
</template>
<script>
export default {
    props:['ViewType'],
    data(){
        return {

        }
    },
    mounted(){
        console.log(this.ViewType)
    }
}
</script>
<style scoped lang="scss">

  @import './Brief.scss';
  </style>