<template>
    <div class="content" v-if="this.ViewType==='newG'">
          
          <span class="title">
            新一代信息通信产教融合论坛在武汉成功举办
          </span>
          <div class="contbox">
              <div class="item_img">
              <img src="@/assets/images/newGeneration.png" />
            </div>
            <p class="text_t">3月30日，“新一代信息通信产教融合论坛”在武汉举行，本次论坛在工业和信息化部人才交流中心、中国通信企业协会的大力支持下，由中信科移动通信技术股份有限公司（以下简称：中信科移动）主办。旨在深入贯彻落实党的二十大精神、中央经济工作会议精神，大力推进新型工业化，推动信息通信产业高质量发展，创新新一代信息通信技术人才培养机制，统筹职业教育、高等教育、继续教育协同创新，本论坛主题为：以赛促学、以赛促教、以赛促产，推动信息通信竞赛生态圈发展；深化职普融通、产教融合、科教融汇，助力现代数智产业学院创新发展。</p>

            <p class="text_t">工业和信息化部人才交流中心战略发展委员会副主任陈新、中国通信企业协会数字化转型推进工作委员会秘书长石义涛、中信科移动通信技术股份有限公司行业信息化事业部副总经理方敏等领导出席了本次论坛，并分别致辞。</p>
            <div class="item_img">
              <img src="@/assets/images/newG01.png" />
            </div>
            <p class="text_t">在论坛中，举办了第十一届“大唐杯”全国大学生新一代信息通信技术大赛启动仪式，大赛已经成功举办十届，并且连续四年列入全国普通高校大学生竞赛目录，大赛得到了社会各界的广泛关注和认可。</p>
            <div class="item_img">
              <img src="@/assets/images/newG02.png" />
            </div>
            <p class="text_t">论坛分享环节，中国移动通信联合会教育与考试中心秘书长，联合国教科文组织国际职业技术教育与培训联系中心秘书长盛鸿宇、电子科技大学通信与信息系统国家级实验教学示范中心副主任，实验教学首席教授段景山、重庆电子工程职业学院通信工程学院院长陶亚雄、江西应用科技学院电子信息产业学院院长刘春斌、中信科移动教育业务总监，全国行职委通信分委会专业建设专委会委员孙中亮，分别做了主题为《做实产教融合共同体——培育新质生产力人才》、《数字化转型背景下创新拔尖人才实验教学探索与实践》、《深耕ICT产教融合沃土，培育智能网联跨学科人才》、《应用型本科高校现代产业学院建设实践与思考》、《深度产教融合模式促进数字人才培养质量提升》的精彩分享。</p>
            <div class="item_img">
              <img src="@/assets/images/newG03.png" />
            </div>
            <p class="text_t">论坛最后，举行了隆重的产教融合签约仪式，行业信息化事业部副总经理方敏作为中信科移动的代表与各方院校进行多项重要合作的签约，不仅彰显了各方在产教融合方面的决心和信心，也标志着中信科移动在政、产、学、研、用，深度融合进程中迈出了坚实的一步，将为产教融合的未来注入新活力。</p>
            <div class="item_img">
              <img src="@/assets/images/newG04.png" />
            </div>
            <p class="text_t">本次论坛的举办，为信息通信产业与教育界搭建了一个深入交流、合作共赢的平台。中信科移动将汇聚各方优势资源，与各院校共同推动产教融合事业发展。加强多方沟通与协作，推进落实高质量人才培养、技术创新、成果转化等方面工作。</p>
            <div class="img_box">
            </div>
          </div>
        </div>
</template>
<script>
export default {
    props:['ViewType'],
    data(){
        return {

        }
    }
}
</script>
<style scoped lang="scss">

  @import './Brief.scss';
  </style>