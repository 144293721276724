<template>
  <div class="content" v-if="this.ViewType === 'elevenB'">
    <span class="title">
      第十一届“大唐杯”全国大学生新一代信息通信技术大赛暨全国双师型教师新一代信息通信技术大赛全国总决赛成功举办
    </span>
    <div class="contbox">
      <p class="text_t">
        2024年7月10日至24日，第十一届“大唐杯”全国大学生新一代信息通信技术大赛暨全国双师型教师新一代信息通信技术大赛（以下简称：大赛或大唐杯）全国总决赛武汉站、北京站分别在武汉职业技术学院、武汉理工大学、北方工业大学落下帷幕。与会嘉宾就本届大赛在新工科人才培养、数智化人才发展、产教融合、创新产教育人模式、培育新质生产力人才等方面的促进作用和社会贡献给予了高度评价。
      </p>
      <div class="item_img">
        <img src="@/assets/images/yuwan/eleOne.png" />
      </div>
      <p class="text_t center">
        第十一届“大唐杯”全国总决赛颁奖典礼嘉宾合影（武汉职业技术学院）
      </p>
      <p class="text_t">
        工业和信息化部人才交流中心教育培训处处长曲来军，中国通信协会副秘书长王涛，中国信科集团人力资源部主任华晓东，中信科移动副总经理李凯钢，武汉职业技术学院党委书记、校长鄢烈洲，武汉理工大学党委常委、副校长沈革武，北方工业大学副校长栗苹，国家职业教育产教融合高端智库主任、天津大学讲席教授潘海生，北京邮电大学教务处处长卞佳丽，以及来自全国参与工程实践赛的院校、企业相关领导出席全国总决赛闭幕式暨颁奖典礼。
      </p>
      <div class="item_img">
        <img src="@/assets/images/yuwan/eleTwo.png" />
      </div>
      <p class="text_t center">
        第十一届“大唐杯”全国总决赛颁奖典礼嘉宾合影（武汉理工大学）
      </p>
      <p class="text_t">
        本届“大唐杯”吸引了全国650余所高校5万余名师生踊跃参赛。大赛设置信息通信工程实践赛和产教融合5G+创新应用设计赛两个赛道，以信息通信技术领域的真标准创新、真设备实践、真问题解决、真场景应用、真行业赋能为核心。创新应用设计赛以产业实际问题和创新应用案例为重点。大赛组委会收到了来自全国200余所高校670个团队提出的信息通信技术赋能产业应用解决方案，为培育数字化创新人才提供了广阔舞台。
      </p>
      <p class="text_t" style="margin-top: 0;">
        中信科移动作为本次大赛的主要承办单位，以“创新6G标准，加速5G建设，服务5G新场景，赋能5G新应用”为己任，不断深化同全国高校在卓越工程师学院、现代产业学院、现场工程师学院等方面的深度合作，推动实训平台、双师培养、产品设计开发、工程项目管理、5G+垂直产业创新应用、新工科数字化人才培养等方面不断融合，助力高校实现卓越人才培养和专业建设双提升，实现“企业用人”与“高校育人”双循环。
      </p>
      <p class="text_t" style="margin-top: 0;">
        “大唐杯”充分依托中信科移动在6G标准创新、5G技术商用、产品研发、网络建设及5G+垂直产业创新应用等方面的产业成果，不断提升参赛选手技术理论创新和工程实践创新的能力，助力高校电子信息类专业高质量发展，为数字化人才培养添砖加瓦。
      </p>
      <div class="item_img">
        <img src="@/assets/images/yuwan/eleThree.png" />
      </div>
      <p class="text_t">第十一届“大唐杯”全国大学生新一代信息通信技术大赛现场</p>
      <div class="item_img center">
        <img src="@/assets/images/yuwan/eleFour.png" />
      </div>
      <p class="text_t center">
        第十一届“大唐杯”全国总决赛颁奖典礼嘉宾合影（北方工业大学）
      </p>
      <p class="text_t">
        全国总决赛期间，来自武汉理工大学、天津大学、北京航空航天大学、北京化工大学、华中师范大学等多所院校的大赛组委会专家委员就竞赛的未来发展方向、前沿技术动态、产教融合趋势、新工科人才培养等议题在大赛专家委员会会议上进行了专题报告。会后，中信科移动组织与会嘉宾和参会教师前往中国信科集团展厅及校企共建的5G+产教融合基地进行了丰富多彩的参观交流活动。
      </p>
      <div class="item_img">
        <img src="@/assets/images/yuwan/eleFive.png" />
      </div>

      <p class="text_t center">第十一届“大唐杯”全国大学生新一代信息通信技术大赛</p>
      <p class="text_t center">专家委员会会议（武汉专场）</p>
      <p class="text_t">
        本届“大唐杯”由工业和信息化部人才交流中心、中国通信企业协会主办，中信科移动通信技术股份有限公司承办，北方工业大学、北京邮电大学、电子科技大学、北京航空航天大学、北京化工大学、武汉理工大学、武汉职业技术学院、武汉软件工程职业学院协办。大赛已成功举办十一年，并连续四年入选全国普通高校大学生竞赛分析报告竞赛目录。大赛始终践行“以赛促学、以赛促教”的办赛理念，围绕教育部、工信部倡导的“政、产、学、研、用”推动产教深度融合，落实落地新工科人才培养新举措，创新工程人才培养体系，提升创新拔尖人才培养质量。
      </p>
      <p class="text_t" style="margin-top: 0;">
        未来“大唐杯”将继续作为深化产教融合的纽带，持续构建信息通信产业与数字化人才发展生态圈，持续推动新一代信息通信技术赋能千行百业，助力高等教育高质量发展。
      </p>
      <div class="img_box"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["ViewType"],
  data() {
    return {};
  },
  mounted() {
    console.log(this.ViewType);
  },
};
</script>
<style scoped lang="scss">
@import "./Brief.scss";
</style>